import PropTypes from 'prop-types'
import React from 'react'

import styles from './OIntro.module.scss'

const OIntro = ({ title, subtitle, ...props }) => {
  let titleMarkup

  switch (typeof title) {
    case 'string':
      titleMarkup = <h1>{title}</h1>
      break
    case 'object':
      titleMarkup = title
      break
    default:
      titleMarkup = false
  }

  let subtitleMarkup
  switch (typeof subtitle) {
    case 'string':
      subtitleMarkup = <p>{subtitle}</p>
      break
    case 'object':
      subtitleMarkup = subtitle
      break
    default:
      subtitleMarkup = false
  }

  return (
    <header className={styles.header} {...props}>
      <div className={styles.headerInner}>
        {titleMarkup}
        {subtitleMarkup}
      </div>
    </header>
  )
}

OIntro.propTypes = {
  title: PropTypes.oneOf([PropTypes.string, PropTypes.node, PropTypes.boolean])
    .isRequired,
  subtitle: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.node,
    PropTypes.boolean,
  ]),
}

OIntro.defaultProps = {
  subtitle: false,
}

export default OIntro

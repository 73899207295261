import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'

import AColumns from '../components/01-atoms/AColumns'
import AContainer from '../components/01-atoms/AContainer'
import ASection from '../components/01-atoms/ASection'
import AType from '../components/01-atoms/AType'
import ALogoGlasses from '../components/01-atoms/ALogoGlasses'

import OIntro from '../components/03-organisms/OIntro'

const IndexPage = ({ data }) => (
  <Layout>
    <Helmet>
      <meta
        name="description"
        content="Augenoptiker »Optique - Meisterhandwerk und Brillenmanufaktur« in Stuttgart-Süd in der Nähe des Marienplatzes."
      />
    </Helmet>

    <OIntro
      title={
        <AType className="title">
          <ALogoGlasses />
          Durchblicken im Groß&shy;stadt&shy;dschungel
        </AType>
      }
      subtitle={
        <AType type="h1" className="subtitle">
          Optique – Meisterhandwerk und Brillenmanufaktur
          <br /> Augenoptiker in Stuttgart-Süd
        </AType>
      }
    />

    <AContainer>
      <ASection type="secondary">
        <AType className="subtitle">
          Die Brille als ganzheitliches Element aus Funktionalität und
          Accessoire. Angefangen beim Sehcheck über die ausführliche Sehberatung
          für Ihre speziellen Einsatzgebiete.{' '}
        </AType>
      </ASection>

      <AColumns>
        <Img
          fluid={data.shopPlant.childImageSharp.fluid}
          backgroundColor="#e6ba68"
          alt="Augenoptiker »Optique Meisterhandwerk« in Stuttgart-Süd"
        />
      </AColumns>
      <AColumns float="right">
        <Img
          fluid={data.shopCo.childImageSharp.fluid}
          backgroundColor="#e6ba68"
          alt="Optikerin Corinna Becht in der Optique Stuttgart"
          style={{
            marginTop: 60,
            marginBottom: -90,
          }}
        />

        <ASection>
          <AType type="h2">Der Laden — Ein Blick in die Optique</AType>

          <AType>
            Im{' '}
            <strong>
              Augenoptikerfachgeschäft Optique Meisterhandwerk im Stuttgarter
              Süden
            </strong>{' '}
            ganz in der Nähe des Marienplatzes, vereint die
            Augenoptikermeisterin und <strong>Inhaberin Corinna Becht</strong>{' '}
            kompetentes Fachwissen und die Liebe zu ausgewählter Brillenmode mit
            meisterlicher Handwerkskunst.
          </AType>

          <AType>
            »Weil kein anderes Accessoire unseren Charakter so prägt wie die
            Brille, setzen wir in der Optique immer auf eine ganzheitliche
            Beratung. Angefangen beim <Link to="/leistungen/">Sehtest</Link>,
            geht es über die einsatzgerechte Glasberatung bis hin zur richtigen
            Fassungsauswahl, welche mit Ihren Glasstärken harmoniert. Das
            Ergebnis: Ein Lieblingsstück, welches den persönlichen Stil des
            Trägers zum Ausdruck bringt und zum geliebten Begleiter im Alltag
            wird.«
          </AType>

          <AType>
            Ausgefallene Mode kleiner Independent{' '}
            <Link to="/marken/">Labels und Manufakturen</Link> ist hier zuhause
            – aber auch die nützliche Computerarbeitsbrille im
            Einstiegspreissegment findet ihren Platz im{' '}
            <strong>großen Sortiment</strong>. Der in eigenem Charme
            eingerichtete Laden lädt zum{' '}
            <strong>individuellen Beratungsgespräch</strong> in ungezwungener,
            entspannter Atmosphäre ein. Als besonderen Service bietet die
            Optique auch <Link to="/termin/">Termine nach Vereinbarung</Link>{' '}
            an. Genießen Sie eine Beratung, bei denen auch Ihre Begleitung dabei
            sein kann, ganz ohne den überall gegenwärtigen Zeitdruck. Um das
            optimale Sehergebnis zu garantieren werden modernste Messgeräte
            eingesetzt und{' '}
            <Link to="/leistungen/">
              der Sehtest durch die revolutionäre 3D Refraktion zum Erlebnis.
            </Link>
          </AType>

          <AType>
            <strong>»Passt wie maßgeschneidert«</strong>
            <br />
            Wir machen Ihre Lieblingsbrille passend – mit handwerklichem
            Geschick wird die Fassung in der hauseigenen Werkstatt Ihren
            anatomischen Gegebenheiten angepasst und so zu einem absoluten
            Unikat. Das Einpassen der Gläser findet ebenfalls im Haus statt –{' '}
            <strong>Qualität und Perfektion</strong> ist hier Meistersache.
          </AType>
        </ASection>
      </AColumns>
      <AColumns>
        <Img
          fluid={data.shopBack.childImageSharp.fluid}
          backgroundColor="#e6ba68"
          alt="Blick in die Optique"
        />
      </AColumns>
    </AContainer>
  </Layout>
)
IndexPage.propTypes = {
  data: PropTypes.shape.isRequired,
}

export default IndexPage

export const query = graphql`
  query {
    shopBack: file(
      relativePath: { eq: "shop/augenoptiker_optique__MG_2727_8_9.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, excludeOriginal: true) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    shopCo: file(
      relativePath: { eq: "shop/augenoptiker_optique__MG_2769_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, excludeOriginal: true) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    shopPlant: file(
      relativePath: { eq: "shop/augenoptiker_optique_JPA01210.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, excludeOriginal: true) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

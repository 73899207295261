// @flow
import PropTypes from 'prop-types'
import React from 'react'
import styles from './AColumns.module.scss'

const classNames = require('classnames')

const AColumns = ({ children, float, ...props }) => {
  const classes = classNames({
    [styles.columns]: true,
    [styles.columns__right]: float === 'right',
  })

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  )
}

AColumns.propTypes = {
  children: PropTypes.node.isRequired,
  float: PropTypes.string,
}

AColumns.defaultProps = {
  float: 'left',
}

export default AColumns

// @flow
import PropTypes from 'prop-types'
import React from 'react'
import styles from './AContainer.module.scss'

const AContainer = ({ children, className, ...props }) => (
  <div className={`${styles.root} ${className}`} {...props}>
    {children}
  </div>
)

AContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

AContainer.defaultProps = {
  className: '',
}

export default AContainer

// @flow
import React from 'react'
import PropTypes from 'prop-types'

import styles from './ALogoGlasses.module.scss'
import glasses from '../../assets/optique-glasses.svg'

const ALogoGlasses = ({ alt, ...props }) => (
  <img
    src={glasses}
    className={styles.glasses}
    alt={alt}
    width="50"
    height="10"
    {...props}
  />
)

ALogoGlasses.propTypes = {
  alt: PropTypes.string,
}

ALogoGlasses.defaultProps = {
  alt: '',
}

export default ALogoGlasses
